<template>
  <div class="bg-white square pb-8">
    <div class="container mx-8 pt-5">
      <span class="title-box">INTRO BANNER UPLOADER</span>
    </div>
    <div
      class="w-auto h-3/5 mx-8 my-4 flex justify-center items-center border-solid border-2 border-gray"
    >
      <img :src="imageUrl" alt="" />
    </div>

    <div class="w-auto flex mx-8 pt-2 justify-center items-center">
      <img src="../../assets/images/upload-icon.svg" alt="" />

      <label class="custom-file-upload">
        <input
          @change="onFileSelected"
          ref="file"
          type="file"
          accept="image/*"
          id="file"
          class="d-none"
        />

        <span class="upload-text ml-2">Upload new image</span><br />
      </label>
    </div>
    <div class="w-auto flex mx-8 pt-3 justify-center items-center">
      <span
        >Recomended <br />
        1366 x 769 px</span
      >
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  data: () => ({
    image: "",
    imageUrl: "",
    imageName: "",
    imageToDelete: "",
    selectedFile: null,
    uploadValue: 0,
    picture: null,
    pages: [],
    elemento: {},
    //para el getSection
    sections: [],
    section: {},
    path: "",
    pathDelete: "",
  }),
  props: ["page", "item"],
  mounted() {
    if (this.page === "distributor" || this.page === "about-us") {
      this.getPages();
    }
    if (this.page === "home-sections" && this.item) {
      this.getSections();
    }
  },
  methods: {
    onFileSelected(event) {
      if (event.target.files[0] == undefined) {
        return;
      }
      this.selectedFile = event.target.files[0];
      this.onUpload();
    },
    onUpload() {
      const loading = this.$vs.loading({
        color: "#0276FF",
      });
      //variable auxiliar
      this.imageToDelete = this.imageName;
      this.imageName = this.selectedFile.name;

      if (this.page === "home-sections") {
        this.pathDelete = `${this.item}/${this.imageToDelete}`;
        this.path = `${this.item}/${this.selectedFile.name}`;
      } else {
        this.pathDelete = `${this.imageToDelete}`;
        this.path = `${this.selectedFile.name}`;
      }

      const deleteRef = firebase
        .storage()
        .ref(`/${this.page}/${this.pathDelete}`);

      const storageRef = firebase.storage().ref(`/${this.page}/${this.path}`);

      const task = storageRef.put(this.selectedFile);

      task.on(
        "state_changed",
        (snapshot) => {
          let percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.uploadValue = percentage;
        },
        (error) => console.log(error.message),
        () => {
          this.uploadValue = 100;
          task.snapshot.ref.getDownloadURL().then((url) => {
            //console.log("URL", url);
            this.imageUrl = url;
            loading.close();
            //edit page para paginas dist y about-us
            if (this.page === "distributor" || this.page === "about-us") {
              this.editPage();
            }
            if (this.page === "home-sections") {
              this.editSection();
            }
            if (this.imageToDelete != this.imageName) {
              deleteRef
                .delete(this.imageToDelete)
                .then(() => {
                  console.log("Imagen Borrada");
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        }
      );
    },
    editPage() {
      let payload = {
        background_image: this.imageUrl,
        description: this.elemento.description,
        title: this.elemento.title,
        id: this.elemento.id,
        image_name: this.imageName,
      };
      this.$store
        .dispatch("AdminModule/updatePages", payload)
        .then(() => {
          this.getPages();
        })
        .catch((error) => {
          this.$notify({
            group: "foo",
            title: "Error",
            text: error,
            type: "warn",
            duration: 3000,
          });
        });
    },
    getPages() {
      const loading = this.$vs.loading({
        color: "#0276FF",
      });
      this.$store.dispatch("AdminModule/getPages").then((response) => {
        this.pages = response;
        this.elemento = this.pages.find((el) => el.page === this.page);
        this.imageUrl = this.elemento.background_image;
        this.imageName = this.elemento.image_name;
        loading.close();
      });
    },
    editSection() {
      let payload = {
        button_name: this.section.button_name,
        pdf_url: this.section.pdf_url,
        subtitle: this.section.subtitle,
        title: this.section.title,
        type_banner: this.section.type_banner,
        background_image: this.imageUrl,
        id: this.section.id,
        image_name: this.imageName,
      };
      this.$store
        .dispatch("LandingModule/updateSections", payload)
        .then(() => {
          this.getSections();
        })
        .catch((error) => {
          this.$notify({
            group: "foo",
            title: "Error",
            text: error,
            type: "warn",
            duration: 3000,
          });
        });
    },
    getSections() {
      const loading = this.$vs.loading({
        color: "#0276FF",
      });
      this.$store.dispatch("LandingModule/getSections").then((response) => {
        this.sections = response;
        this.section = this.sections.find((el) => el.type_banner === this.item);
        this.imageUrl = this.section.background_image;
        this.imageName = this.section.image_name;
        loading.close();
      });
    },
  },
};
</script>

<style>
.square {
  width: 399px;
  height: 45vh;
  margin-right: 8px;
  border-radius: 5px;
}
input[type="file"] {
  display: none;
}

@media (max-height: 899px) {
  .square {
    height: 55vh;
  }
}
</style>
