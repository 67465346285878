<template>
  <div class="height-total">
    <span class="uploader-text">Pages | Nosotros</span>
    <div class="flex">
      <div>
        <Square page="about-us" item="page"></Square>
        <!-- <div class="mt-8 flex justify-center items-center">
          <img src="../../../assets/images/trash.svg" alt="" />
          <span class="delete-text ml-2">Delete this Page</span>
        </div> -->
      </div>

      <div class="w-full bg-white rectangle">
        <div class="w-4/5 mx-8 pt-5">
          <span class="title-box">DESCRIPTION INTRO BANNER NOSOTROS</span>
        </div>
        <div class="flex flex-col mx-8 pt-9">
          <vs-input
            label="Title (Page name)"
            v-model="page.title"
            placeholder="Siguenos en nuestras redes"
            class="input-bluexpress-component block mt-2 mb-6"
          />
          <label class="text-xs ml-4 mb-3">Description</label>
          <vue-editor
            label="Description"
            v-model="page.description"
            class="input-bluexpress-component"
          />
        </div>
        <div class="block p-8 text-right mr-8">
          <span
            class="text-s text-blue-500 hover:text-blue-800 hover:underline cursor-pointer"
            @click="editPage"
            >Saved</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Square from "@/components/UploaderSquare/Index";
export default {
  components: {
    Square,
  },
  data() {
    return {
      title: "value",
      description: "value",
      page: {},
    };
  },
  computed: {
    validation() {
      if (this.page.title !== "" && this.page.description !== "") {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getPages();
  },
  methods: {
    getPages() {
      const loading = this.$vs.loading({
        color: "#0276FF",
      });
      this.$store.dispatch("AdminModule/getPages").then((response) => {
        this.pages = response;
        this.page = this.pages.find((item) => item.page === "about-us");
        loading.close();
      });
    },
    editPage() {
      if (!this.validation) {
        this.openNotification(null, "danger", 5000);
        return;
      }
      const payload = this.page;
      this.$store
        .dispatch("AdminModule/updatePages", payload)
        .then(() => {
          this.getPages();
        })
        .catch((error) => {
          this.$notify({
            group: "foo",
            title: "Error",
            text: error,
            type: "warn",
            duration: 3000,
          });
        });
    },
    openNotification(position = null, color, duration) {
      this.$vs.notification({
        color,
        position,
        duration,
        title: "Unable to edit!",
        text: "This page can't be edited, please fill all the fields!",
      });
    },
  },
};
</script>

<style>
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ql-toolbar.ql-snow {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.height-total {
  height: calc(100vh - 131px);
}
</style>
